import { Button } from "@chakra-ui/react";

const AuthButton = ({ children, bg, color, type, isLoading }) => {
  return (
    <Button
      bg={bg}
      color={color}
      display={"flex"}
      gap="16px"
      justifyContent={"center"}
      alignItems={"center"}
      width={["full", "full"]}
      height="64px"
      type={type}
      isLoading={isLoading}
      // onClick={action && action}
    >
      {children}
    </Button>
  );
};

export default AuthButton;
