import { Box, Container } from "@chakra-ui/react";
import React from "react";

const SectionContainer = (props) => {
  return (
    <Box
      width={["full", `${props.width || "full"}`]}
      height={props.hero && ["fit-content", "80vh"]}
      backgroundImage={props.hero && "url('../../assets/images/heroBg.png')"}
      backgroundPosition={"center"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      bg={props.bg}
      borderRightRadius={["none", `${props.br || "none"}`]}
      py={
        props.py && props.hero
          ? props.py
          : ["20px", "20px", "30px", "50px", "50px"]
      }
      
    >
      <Container maxW={["100%", "100%", "90%"]}>{props.children}</Container>
    </Box>
  );
};

export default SectionContainer;
