import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import TestimonialCard from "./TestimonialCard";

const testimonials = [
  {
    id: 1,
    name: "Abdulsalam Taofeek",
    title: "Forex trader",
    desc: "Using AI for trading has been a game-changer for me. It's like having a tireless, analytical partner that never lets emotions get in the way. My portfolio have seen more consistent gains, and I feel more confident in my trading decisions. AI has definitely improved my financial strategy.",
  },
  {
    id: 2,
    name: "Giwa Mohammed",
    title: "Forex trader",
    desc: "Having an AI chat companion for forex trading has been a delightful experience. It's like having a friend who's available 24/7 to chat, answer questions, and provide useful information about my trades. Whether I want to learn something new, discuss a startegy, or just have a friendly conversation, my AI chat companion is always there, making my  trading journey more enjoyable and less lonely.",
  },
  {
    id: 3,
    name: "Stanley Adesida",
    title: "Forex and Crypto trader",
    desc: "The impact of QuidX AI on my trading journey has been truly impressive. With its ability to analyze vast amounts of market data in real-time, I've not only increased my profits but also minimized losses. It's like having a financial advisor who never sleeps, always working to optimize my portfolio. QuidX has undoubtedly boosted my financial success.",
  },
  {
    id: 4,
    name: "Adegoke Wasiu",
    title: "Co-founder Bitgifty",
    desc: "QuidX AI has revolutionized my approach to psychology management in forex trading. By eliminating emotional biases, QuidX AI ensures that my decisions are purely data-driven and objective. This has significantly reduced stress and anxiety associated with trading, allowing me to maintain a disciplined and rational mindset.",
  },
];
export const TestimonialCarousel = () => {
  const [start, setStart] = useState(0);

  const handlePrev = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };

  const handleNext = () => {
    if (start + 3 < testimonials.length) {
      setStart(start + 1);
    }
  };
  return (
    <VStack display={["none", "block"]}>
      <HStack
        width={"full"}
        gap={["25px", "25px", "25px"]}
        justifyContent={"center"}
      >
        {/* <AnimatePresence mode="wait" initial={false}> */}
        {testimonials.slice(start, start + 3).map((item, i) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <TestimonialCard
              key={item.id}
              title={item.title}
              name={item.name}
              desc={item.desc}
            />
          </motion.div>
        ))}
        {/* </AnimatePresence> */}
      </HStack>
      <br />
      <HStack width={"full"} justifyContent={"space-between"}>
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </VStack>
  );
};

export const MobileTestCarousel = () => {
  const [start, setStart] = useState(0);

  const handleNext = () => {
    setStart(start === testimonials.length - 1 ? 0 : start + 1);
  };

  const handlePrev = () => {
    setStart(start === 0 ? testimonials.length - 1 : start - 1);
  };

  return (
    <VStack display={["block", "none"]} width={"100%"}>
      <HStack width={"full"} gap={["25px", "25px", "25px"]}>
        {testimonials.map(
          (item, i) =>
            i === start && (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
              >
                <TestimonialCard
                  key={item.id}
                  title={item.title}
                  name={item.name}
                  desc={item.desc}
                />
              </motion.div>
            )
        )}
      </HStack>
      <br />
      <HStack width={"full"} justifyContent={"space-between"}>
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </VStack>
  );
};
