import { EmailIcon, LockIcon } from "@chakra-ui/icons";
import { Box, HStack, Image, useToast, Text, VStack } from "@chakra-ui/react";
import AuthButton from "../components/AuthButton";
import AuthInput from "../components/AuthInput";
import { Link, useNavigate } from "react-router-dom";

import { set, useForm } from "react-hook-form";
import axios from "axios";
import { createContext, useState } from "react";
export const UserContext = createContext();

const Signin = () => {
  const navigate = useNavigate();
  const toast = useToast(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const login = async (data) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data)
      .then((response) => {
        setLoading(false);
        console.log(response.data)
        setUserInfo(response.data.data);
        toast({ status: "success", title: "Login Successful" });
        localStorage.setItem("quidsToken", response.data.token);
        localStorage.setItem("userId", response.data.data.userId);

        navigate("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        toast({ status: "warning", title: error.response.data.message });
      });
  };
  return (
    <Box
      width="full"
      height={"100vh"}
      padding={"20px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <HStack
        width={"full"}
        height={"95%"}
        // borderColor={"black"}
        // borderWidth={1}
        // borderStyle={"solid"}
        gap={["0px", "0px", "51px"]}
      >
        <Box
          width={["0%", "50%", "50%"]}
          height={"100%"}
          borderRadius={"32px"}
          background="white"
          display={("none", "flex", "flex")}
          justifyContent={"center"}
          alignItems={"center"}
          bgImage={"url(/assets/images/bg.png)"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
        >
          <Image src="/assets/images/metaverse.png" />
        </Box>
        <Box
          width={["full", "100%", "50%"]}
          flex={1}
          paddingRight={["0px", "0px", "190px"]}
          display={"flex"}
          flexDir={"column"}
          marginLeft={0}
          alignItems={["center", "center", "start"]}
        >
          <form style={{ width: "100%" }} onSubmit={handleSubmit(login)}>
            <VStack width={"full"} alignItems={"flex-start"} gap={"2"}>
              {" "}
              <Text
                color={"#141414"}
                fontWeight={"700"}
                fontSize={["30px", "36px", "48px"]}
                textAlign={["center", "left", "left"]}
                lineHeight={"normal"}
                marginBottom={"48px"}
              >
                {" "}
                Sign in to QuidX
              </Text>
              <AuthInput
                placeholder={"Email Address"}
                icon={<EmailIcon />}
                type={"email"}
                name={"email"}
                register={register("email")}
                required={true}
              />
              <AuthInput
                placeholder={"Password"}
                type={"password"}
                name={"password"}
                register={register("password")}
                icon={<LockIcon />}
                required={true}
              />
              <Text marginBottom={"50px"} alignSelf={"flex-start"}>
                <Link to={"/forgot-password"}>Forgot Password?</Link>
              </Text>
              <Box
                width="full"
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                gap={"24px"}
                marginBottom={"12px"}
              >
                <AuthButton
                  bg="black"
                  color="white"
                  isLoading={loading}
                  type={"submit"}
                >
                  Sign In
                </AuthButton>
                <AuthButton bg="#F6FFF5" color="black">
                  <Image src="/assets/images/google.png" />
                  Sign up with Google
                </AuthButton>
              </Box>
              <HStack>
                {" "}
                <Text display={"flex"} gap={"2px"} alignSelf={"center"}>
                  Don't have an account?{" "}
                </Text>
                <Link to={"/signup"}>
                  <Text fontWeight={700} cursor={"pointer"}>
                    Sign Up
                  </Text>
                </Link>{" "}
              </HStack>
            </VStack>
          </form>
        </Box>
      </HStack>
    </Box>
  );
};

export default Signin;
