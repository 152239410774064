import {
  Box,
  Container,
  HStack,
  Image,
  Text,
  Link,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BsInstagram, BsLinkedin, BsTiktok, BsTwitter } from "react-icons/bs";

import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <Box width={"full"}>
      <Container maxWidth={"90%"} py={"20px"}>
        <VStack width={"full"} gap={"40px"} alignItems={"center"}>
          <HStack
            width={"full"}
            flexDir={["column", "column", "row"]}
            justifyContent={["center", "center", "space-between"]}
            alignItems={["center", "center", "flex-start"]}
            gap={10}
          >
            <VStack
              justifyContent={"center"}
              width={"full"}
              alignItems={["center", "center", "flex-start"]}
              height={["auto", "auto", "250px"]}
            >
              <Image
                src="/assets/images/quidsailogo.png"
                height={["30px", "50px", "70px"]}
              />
            </VStack>
            <VStack
              gap={["10px", "10px", "16px"]}
              width={"full"}
              alignItems={["center", "center", "flex-start"]}
            >
              <Text
                color={"#141414"}
                fontSize={["16px", "18px"]}
                fontWeight={"500"}
              >
                Quick Links
              </Text>
              <VStack gap={["8px", "8px", "16px"]} alignItems={"flex-start"}>
                <Link color={"#333333"} fontSize={["14px", "16px"]}>
                  <NavLink to={"/"}>Home</NavLink>
                </Link>
           
                <Link color={"#333333"} fontSize={["14px", "16px"]}>
                  <NavLink to="/pricing">Pricing</NavLink>
                </Link>
                <Link color={"#333333"} fontSize={["14px", "16px"]}>
                  <NavLink to="/faq">FAQ</NavLink>
                </Link>
              </VStack>
            </VStack>
            <VStack
              gap={["10px", "10px", "16px"]}
              width={"full"}
              alignItems={["center", "center", "flex-start"]}
            >
              <Text
                color={"#141414"}
                fontSize={["16px", "18px"]}
                fontWeight={"500"}
              >
                Features
              </Text>
              <VStack
                gap={["8px", "8px", "16px"]}
                alignItems={["center", "center", "flex-start"]}
              >
                <Link color={"#333333"} to="/faq" fontSize={["14px", "16px"]}>
                  <NavLink to={"/pricing"}>Subscriptions</NavLink>
                </Link>
                <Link color={"#333333"} fontSize={["14px", "16px"]}>
                  <a href={"/chat"}>Chat</a>
                </Link>
                <Link color={"#333333"} fontSize={["14px", "16px"]}>
                  <a href={"/calculator"}>Calculator</a>
                </Link>
              </VStack>
            </VStack>

            <VStack
              gap={["10px", "10px", "18px"]}
              width={"full"}
              alignItems={["center", "center", "flex-start"]}
            >
              <Text
                color={"#141414"}
                fontSize={["16px", "18px"]}
                fontWeight={"500"}
              >
                Connect with Us
              </Text>
              <HStack gap={"10px"} fontSize={["28px"]}>
                <a
                  href="https://www.linkedin.com/company/quidx-ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsLinkedin />
                </a>
                <a
                  href="https://www.instagram.com/quidx_ai?igsh=dm9jYXV5MGM5d2Yz"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsInstagram />
                </a>
                <a
                  href="https://x.com/quidx_ai?t=fJpqxtxbSyV2DFVo-UFJGw&s=09"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsTwitter />
                </a>
                <a
                  href="https://www.tiktok.com/@quidx.ai?_t=8kTbrVvU6tS&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <BsTiktok />
                </a>
              </HStack>
            </VStack>
          </HStack>

          <Text
            fontSize={["16px", "18px"]}
            textAlign={"center"}
            fontWeight={"500"}
          >
            QuidX - Copyright © 2024
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default Footer;
