import { Button } from "@chakra-ui/react";
import React from "react";

const CTAButton = ({ text, action, display }) => {
  return (
    <Button
      backgroundColor={"#3EA842"}
      color={"#fff"}
      variant="ghost"
      fontSize={["12px", "14px", "16px"]}
      _hover={{ bg: "#083205", color: "#fff" }}
      onClick={action && action}
      display={display}
      width={"fit-content"}
      borderRadius={"6px"}
    >
      {text}
    </Button>
  );
};

export default CTAButton;
