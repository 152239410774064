import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import React from "react";

const PricingCard = (props) => {
  return (
    <VStack
      width={"full"}
      background={props.role === "main" ? "#3EA842" : "#D3DCD1"}
      py={props.role === "main" ? "60px" : "40px"}
      borderRadius={"24px"}
      borderTop={"5px solid #3EA842"}
      gap={"10px"}
      px={"16px"}
    >
      <VStack gap={"20px"}>
        {" "}
        <Text
          color={props.role === "main" ? "#fff" : "#333333"}
          fontSize={"16px"}
          fontWeight={"500"}
        >
          {props.title}
        </Text>
        <Text
          color={props.role === "main" ? "#fff" : "#333333"}
          fontSize={"16px"}
          fontWeight={"700"}
        >
          ${props.price}/Monthly
        </Text>
      </VStack>

      <VStack alignItems={"flex-start"} gap={"40px"}>
        {props.features.map((feature, id) => {
          return (
            <HStack
              color={props.role === "main" ? "#fff" : "#333333"}
              alignItems={"flex-start"}
              key={id}
            >
              <CheckCircleIcon boxSize={"16px"} />
              <Text fontSize={"16px"} lineHeight={"18px"}>
                {feature}
              </Text>
            </HStack>
          );
        })}
      </VStack>
      <Button
        _hover={{ bg: "#083205", color: "#fff" }}
        bg={props.role === "main" ? "#F8FCF8" : "#3EA842"}
        color={props.role === "main" ? "#3EA842" : "#F8FCF8"}
      >
        Sign up
      </Button>
    </VStack>
  );
};

export default PricingCard;
