import { Box, HStack, Image, Text, useToast } from "@chakra-ui/react";
import AuthButton from "../components/AuthButton";
import AuthInput from "../components/AuthInput";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";

const ForgotPassword = () => {
  const currentUrl = window.location.origin + "/reset-password";
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onSubmitHandler = async (data) => {
    setLoading(true);
    data = { ...data, callbackUrl: currentUrl };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/forgetpasswordbegin`,
        data
      );
      setLoading(false);
      toast({
        title: "Success!",
        description: "Password reset email sent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      width="full"
      height={"100vh"}
      padding={"20px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <HStack width={"full"} height={"95%"} gap={["0px", "0px", "51px"]}>
        <Box
          width={["0%", "50%", "50%"]}
          height={"100%"}
          borderRadius={"32px"}
          background="white"
          display={["none", "flex", "flex"]}
          justifyContent={"center"}
          alignItems={"center"}
          bgImage={"url(/assets/images/bg.png)"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
          overflow={"hidden"}
        >
          <Image src="/assets/images/forgot.png" />
        </Box>
        <Box
          width={["full", "100%", "50%"]}
          flex={1}
          paddingRight={["0px", "0px", "190px"]}
          display={"flex"}
          flexDir={"column"}
        >
          <Text
            color={"#141414"}
            fontWeight={"700"}
            fontSize={["30px", "36px", "48px"]}
            textAlign={"left"}
            lineHeight={"normal"}
            marginBottom={"48px"}
          >
            Forgot Password?
          </Text>

          <Text
            marginBottom={"28px"}
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            color={"#333"}
          >
            Enter the email address associated <br />
            with your account
          </Text>
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <AuthInput
              placeholder="Enter Email Address"
              register={register("email")}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Enter a valid email address",
                },
              })}
            />
            {errors.email && (
              <Text color="red.500">{errors.email.message}</Text>
            )}
            <Box
              width="full"
              display={"flex"}
              flexDir={"column"}
              alignItems={"flex-start"}
              gap={"24px"}
              marginBottom={"12px"}
              marginTop={"50px"}
            >
              <AuthButton
                bg="#3EA842"
                color="white"
                isLoading={loading}
                type="submit"
              >
                Continue
              </AuthButton>
            </Box>
          </form>
        </Box>
      </HStack>
    </Box>
  );
};

export default ForgotPassword;
