import { Box, Container, HStack, Text } from "@chakra-ui/react";
import React from "react";
import FormattedText from "../utils/parseFormatedText";

const ChatCard = ({ role, content, lastAnswer }) => {
  return (
    <Box
      width={"full"}
      background={role === "user" ? "#fff" : "#ECF8ED"}
      py={"20px"}
    >
      <Container maxW={["100%", "100%", "100%", "80%", "70%"]}>
        <HStack
          width={"full"}
          alignItems={"flex-start"}
          gap={["6px", "6px", "6px", "8px", "10px"]}
        >
          <HStack
            minWidth={["30px", "30px", "30px", "45px", "54px"]}
            height={["30px", "30px", "30px", "40px", "48px"]}
            alignItems={"center"}
            justifyContent={"center"}
            bg={"#083201"}
            borderRadius={"4px"}
          >
            <Text
              fontSize={["14px", "14px", "14px", "20px", "24px"]}
              color={"#fff"}
            >
              {role === "user" ? "Q" : "A"}
            </Text>
          </HStack>
          {role === "quidx-ai" ? (
            // <TypeAnimation
            //   style={{ flex: "6", fontSize: "16px" }}
            //   sequence={[value, 800]}
            // />
            <Box dangerouslySetInnerHTML={{__html: `${content}`}}>
              {/* <FormattedText text = {content}/> */}
            </Box>
          ) : (
            <Text flex={"6"} fontSize={"16px"}>
              {content}
            </Text>
          )}
          {/* <Text flex={"6"} fontSize={"16px"}>
            {value}
          </Text> */}
        </HStack>
      </Container>
    </Box>
  );
};

export default ChatCard;
