import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

const AuthInput = ({ name, icon, placeholder, type, register,required }) => {
  return (
    <InputGroup
      marginBottom={"16px"}
      border="2px"
      borderRadius={"4px"}
      borderColor="#3EA842"
      height={"56px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      outline={"none"}
      width={["full", "full", ""]}
    >
      <InputLeftElement pointerEvents="none" height={"full"}>
        {icon}
      </InputLeftElement>
      <Input
        type={type ? type : "text"}
        name={name}
        placeholder={placeholder}
        border={"none"}
        outline={"black"}
        height={"full"}
        required = {required}
        borderRadius={"4px"}
        {...register}
      />
    </InputGroup>
  );
};

export default AuthInput;
