import { LockIcon } from "@chakra-ui/icons";
import { Box, HStack, Image, useToast, Text, VStack } from "@chakra-ui/react";
import AuthButton from "../components/AuthButton";
import AuthInput from "../components/AuthInput";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";

const ResetPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();

  const resetPassword = async (data) => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/user/completeforgetpassword`,
        { ...data, token }
      );
      setLoading(false);
      toast({ status: "success", title: "Password reset successful" });
      navigate("/signin");
    } catch (error) {
      setLoading(false);
      toast({
        status: "error",
        title: error.response?.data?.message || "Password reset failed",
      });
    }
  };

  return (
    <Box
      width="full"
      height={"100vh"}
      padding={"20px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <HStack width={"full"} height={"95%"} gap={["0px", "0px", "51px"]}>
        <Box
          width={["0%", "50%", "50%"]}
          height={"100%"}
          borderRadius={"32px"}
          background="white"
          display={["none", "flex", "flex"]}
          justifyContent={"center"}
          alignItems={"center"}
          bgImage={"url(/assets/images/bg.png)"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
        >
          <Image src="/assets/images/metaverse.png" />
        </Box>
        <Box
          width={["full", "100%", "50%"]}
          flex={1}
          paddingRight={["0px", "0px", "190px"]}
          display={"flex"}
          flexDir={"column"}
          marginLeft={0}
          alignItems={["center", "center", "start"]}
        >
          <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(resetPassword)}
          >
            <VStack width={"full"} alignItems={"flex-start"} gap={"2"}>
              <Text
                color={"#141414"}
                fontWeight={"700"}
                fontSize={["30px", "36px", "48px"]}
                textAlign={["center", "left", "left"]}
                lineHeight={"normal"}
                marginBottom={"48px"}
              >
                Reset Password
              </Text>
              <Text
                marginBottom={"28px"}
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                color={"#333"}
              >
                Enter the new password to be associated <br />
                with your account
              </Text>
              <AuthInput
                placeholder={"Password"}
                type={"password"}
                name={"password"}
                register={register("password", { required: true })}
                icon={<LockIcon />}
              />
              <AuthInput
                placeholder={"Confirm Password"}
                type={"password"}
                name={"confirmPassword"}
                register={register("confirmPassword", { required: true })}
                icon={<LockIcon />}
              />
              <Box
                width="full"
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                gap={"24px"}
                marginBottom={"12px"}
              >
                <AuthButton
                  bg="black"
                  color="white"
                  isLoading={loading}
                  type={"submit"}
                >
                  Reset Password
                </AuthButton>
              </Box>
            </VStack>
          </form>
        </Box>
      </HStack>
    </Box>
  );
};

export default ResetPassword;
