import { Box, HStack, Image, useToast, Text } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

const Verify = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      verifyEmail(token);
    }
  }, [location.search]);

  const verifyEmail = async (token) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/verify/${token}`
      );
      setLoading(false);
      setVerified(true);
      toast({ status: "success", title: "Verification Successful" });
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      setVerified(false);
      toast({
        status: "warning",
        title: error.response?.data?.message || "Verification Failed",
      });
    }
  };

  return (
    <Box
      width="full"
      height={"100vh"}
      padding={"20px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      opacity={loading && 0.5}
    >
      <HStack width={"full"} height={"95%"} gap={["0px", "0px", "51px"]}>
        <Box
          width={["0%", "50%", "50%"]}
          height={"100%"}
          borderRadius={"32px"}
          background="white"
          display={["none", "flex", "flex"]}
          justifyContent={"center"}
          alignItems={"center"}
          bgImage={"url(/assets/images/bg.png)"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
        >
          <Image src="/assets/images/metaverse.png" />
        </Box>
        <Box
          width={["full", "100%", "50%"]}
          flex={1}
          paddingRight={["0px", "0px", "190px"]}
          display={"flex"}
          flexDir={"column"}
          marginLeft={0}
          alignItems={["center", "center", "center"]}
        >
          {verified ? (
            <Image src="/assets/images/verify.png" height={"100px"} />
          ) : (
            <Image src="/assets/images/notverified.jpg" height={"100px"} />
          )}
          <Text
            color={"#141414"}
            fontWeight={"700"}
            fontSize={["30px", "36px", "48px"]}
            textAlign={["center"]}
            lineHeight={"normal"}
            marginBottom={"28px"}
          >
            Email Verification
          </Text>
          {verified ? (
            <Text
              color={"#333333"}
              fontSize={["14px", "14px", "18px"]}
              lineHeight={["18px", "22px", "31px"]}
              width={["100%", "70%", "70%"]}
              textAlign={"center"}
            >
              Your email was verified. You can continue with the application.
            </Text>
          ) : (
            <Text
              color={"#333333"}
              fontSize={["14px", "14px", "18px"]}
              lineHeight={["18px", "22px", "31px"]}
              width={["100%", "70%", "70%"]}
              textAlign={"center"}
            >
              Your email was not verified. Please try signing in again.
            </Text>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default Verify;
