import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import SectionContainer from "../components/SectionContainer";
import ThemeLayout from "../components/ThemeLayout";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import Header from "../components/Header";

const FAQ = () => {
  const data = [
    {
      id: 1,
      title: "How secure is QuidX?",
      desc: "Security is a top priority for us. We employ industry-standard encryption protocols to protect your data, regularly update our systems to patch vulnerabilities, and conduct security audits. Rest assured, we're committed to safeguarding your information.",
    },
    {
      id: 2,
      title: "Do I need previous trading experience to use QuidX?",
      desc: "No, you don't need prior trading experience to use QuidX. We cater to traders of all levels, providing educational resources and tools suitable for beginners and experienced traders alike.",
    },
    {
      id: 3,
      title: "Are there any hidden fees?",
      desc: "No, we believe in transparency. You won't encounter hidden fees on our website. Our pricing is clear, and you'll only be charged for the services or products you select during your transaction. No surprises!",
    },
    {
      id: 4,
      title: "How can I reach customer support?",
      desc: "You can easily reach our dedicated customer support team by sending a mail to QuidX@gmail.com during our business hours. We're here to assist you with any questions or concerns you may have.",
    },
  ];
  return (
    <ThemeLayout>
      <Header showLogo={true} />

      <SectionContainer>
        {" "}
        <VStack gap={"20px"}>
          <Text fontSize={["25px", "25px", "30px"]} fontWeight={700}>
            FAQ's
          </Text>
          <HStack
            width={"full"}
            flexDir={["column", "column", "column", "row"]}
            gap={["30px", "30px", "40px"]}
          >
            <Accordion
              display="flex"
              flexDir="column"
              width={"full"}
              gap="24px"
            >
              {data.map((item) => (
                <AccordionItem key={item.id} borderRadius="4px">
                  {({ isExpanded }) => (
                    <>
                      <h2>
                        <AccordionButton
                          width={"full"}
                          bg={`${isExpanded ? "#F2F2F2" : "#E6E5E5"}`}
                          display="flex"
                          gap="24px"
                          borderLeft="2px"
                          bgColor={isExpanded && "#DAF1DB"}
                          _hover={{ bgColor: "#DAF1DB" }}
                        >
                          {isExpanded ? (
                            <MinusIcon fontSize="12px" />
                          ) : (
                            <AddIcon fontSize="12px" />
                          )}
                          <Box
                            as={"div"}
                            flex="1"
                            textAlign="left"
                            w="100%"
                            fontSize={["14px", "18px"]}
                            fontWeight={500}
                            py={"20px"}
                          >
                            {item.title}
                          </Box>
                        </AccordionButton>
                      </h2>
                      <AccordionPanel
                        pb={4}
                        bgColor={"#DAF1DB"}
                        borderLeft="2px"
                        fontSize={["14px", "18px"]}
                      >
                        {item.desc}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          </HStack>
        </VStack>
      </SectionContainer>
      <SectionContainer>
        <HStack
          display={"flex"}
          flexDir={["column", "row"]}
          width={"full"}
          gap={[5, 5, 10]}
          alignItems={"center"}
          bg={"#1B4B1D"}
          boxShadow={"0px 1px 4px rgba(255, 255, 255, 0.04)"}
          borderRadius={"24px"}
          p={["0px", "96px 56px"]}
          justifyContent={["center", "space-between"]}
          border={"10px solid #F2F2F2"}
          py={"70px"}
          paddingRight={["0px", "160px"]}
        >
          <Text
            color={"#F8FCF8"}
            fontWeight={"500"}
            fontSize={["14px", "14px", "30px"]}
            textAlign={["center", "left"]}
          >
            Ready to Explore Quidx? Claim <br />
            your free account{" "}
          </Text>

          <Button
            bg={"#DAF1DB"}
            color={"black"}
            height={"60px"}
            borderRadius={"4px"}
            fontWeight={"500"}
            paddingX={"70px"}
          >
            Get Started
          </Button>
        </HStack>
      </SectionContainer>
    </ThemeLayout>
  );
};

export default FAQ;
