import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

const TestimonialCard = ({ name, title, desc }) => {
  return (
    <VStack
      justifyContent={"center"}
      alignItems={"center"}
      bgColor={"#D3DCD1"}
      textAlign={"center"}
      borderRadius={"36px"}
      boxShadow={"md"}
      gap={"10px"}
      py={"40px"}
      width={["100%", "300px", "400px"]}
      height={"500px"}
      px={"30px"}
    >
      <Image
        src={"/assets/images/avatar.png"}
        borderRadius={"50%"}
        height={"100px"}
        width={"100px"}
      />
      <Text color={"#1B4B1D"} fontSize={["16px", "20px"]} fontWeight={["600"]}>
        {name}
      </Text>
      <Text color={"#1B4B1D"} fontSize={["14px", "16px"]} fontWeight={["400"]}>
        {title}
      </Text>
      <Text
        color={"#333"}
        fontSize={["12px", "15px"]}
        fontWeight={["500"]}
        className="slide-in-bottom"
        display={"flex"}
        gap={"5px"}
      >
        <Text
          fontSize={"72px"}
          fontWeight={"400"}
          color={"#3EA842"}
          alignSelf={"baseline"}
        >
          “
        </Text>
        {desc}{" "}
        <Text
          fontSize={"72px"}
          fontWeight={"400"}
          color={"#3EA842"}
          alignSelf={"end"}
        >
          “
        </Text>
      </Text>
    </VStack>
  );
};

export default TestimonialCard;
