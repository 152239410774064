import { Box, HStack, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import ProvideCard from "./ProvideCard";
import { motion } from "framer-motion";
import "../App.css";

const provide = [
  {
    id: 1,
    icon: "../../assets/images/provide/two.png",
    title: "ChatX",
    link: "/chat",
    body: "ChatX is your AI-powered mentor that provides you with personalised expert guidiance in the Forex and Web 3 space anywhere, anytime",
  },
  {
    id: 2,
    icon: "../../assets/images/provide/one.png",
    title: "Risk Calculator",
    link: "/calculator",
    body: "Calculate your expected profit or loss on your forex or crypto trade. This helps you practice proper risk management.",
  },
  {
    id: 3,
    icon: "../../assets/images/provide/three.png",
    title: "Trading Tools",
    link: "/chat",
    body: "Our platform provides comprehensive charts, indicators, and market analysis, empowering you to make informed trading decisions based on accurate information.",
  },
  {
    id: 4,
    icon: "../../assets/images/provide/one.png",
    title: "JournalX",
    link: "",
    body: "Use AI to track trades, analyze performance, manage risk, and stay disciplined with ease.",
  },
  {
    id: 5,
    icon: "../../assets/images/provide/two.png",
    title: "AI Analysis",
    body: "Explore the essentials of any crypto project effortlessly. Just enter the project name, website URL or CA and in less than 5 minutes, you'll gain a comprehensive overview and actionable insights. Save time and make informed decisions with ease.",
  },
];

export const Carousel = () => {
  const [start, setStart] = useState(0);

  const handlePrev = () => {
    if (start > 0) {
      setStart(start - 1);
    }
  };

  const handleNext = () => {
    if (start + 3 < provide.length) {
      setStart(start + 1);
    }
  };

  return (
    <Box display={["none", "block"]}>
      <HStack
        width={"full"}
        gap={["25px", "25px", "25px"]}
        justifyContent={"center"}
      >
        {provide.slice(start, start + 3).map((item) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ProvideCard
              key={item.id}
              title={item.title}
              link={item.link}
              icon={item.icon}
              body={item.body}
            />
          </motion.div>
        ))}
      </HStack>
      <HStack width={"full"} justifyContent={"space-between"}>
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </Box>
  );
};

export const MobileCarousel = () => {
  const [start, setStart] = useState(0);

  const handleNext = () => {
    setStart(start === provide.length - 1 ? 0 : start + 1);
  };

  const handlePrev = () => {
    setStart(start === 0 ? provide.length - 1 : start - 1);
  };

  return (
    <VStack display={["block", "none"]} width={"100%"}>
      <HStack width={"full"} gap={["25px", "25px", "25px"]}>
        {provide.map(
          (item, i) =>
            i === start && (
              <motion.div
                key={item.id}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
              >
                <ProvideCard
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                  link={item.link}
                  name={item.name}
                  body={item.body}
                />
              </motion.div>
            )
        )}
      </HStack>
      <br />
      <HStack width={"full"} justifyContent={"space-between"}>
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </VStack>
  );
};
