import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";

const Checkout = ({ type, price, monthly }) => {
  return (
    <VStack
      borderRadius={"4px"}
      gap={0}
      bg={"#E6E5E5"}
      w={["60%", "200px", "290px"]}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
    >
      <Box
        color={"black"}
        p={3}
        fontWeight={500}
        fontSize={["16px", "18px", "20px"]}
        display={"flex"}
        flexDir={"column"}
        alignItems={"start"}
        justifyContent={"start"}
      >
        <Text>{type} Subscription</Text>
        <Text>
          ${price}.00 / {monthly ? "Month" : "Year"}
        </Text>
      </Box>
      <Box bg={"#3EA842"} w={"full"}>
        <Text
          color={"white"}
          fontSize={["20px", "22px", "24px"]}
          fontWeight={500}
          textAlign={"center"}
          p={2}
        >
          Checkout
        </Text>
      </Box>
    </VStack>
  );
};

export default Checkout;
