import {  HStack, Text,  } from "@chakra-ui/react";
import React from "react";

const ResultCard = (props) => {
  return (
    <HStack padding={"10px"} gap={"20px"}>
      <HStack
        width={["48px", "48px", "48px"]}
        height={["48px", "48px", "48px"]}
        alignItems={"center"}
        justifyContent={"center"}
        background={"#D3DCD1"}
        boxShadow={"0px 4px 2px rgba(0, 0, 0, 0.05)"}
        borderRadius={"10px"}
        fontSize={["27px", "27px", "54px"]}
        color={"#3EA842"}
      >
        {props.icon}
      </HStack>
      <Text
        fontSize={["12px", "12px", "16px"]}
        fontWeight={"500"}
        color={"#F8FCF8"}
      >
        {props.text}
      </Text>
    </HStack>
  );
};

export default ResultCard;
