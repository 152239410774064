import { EmailIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  HStack,
  Image,
  Text,
  VStack,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import AuthButton from "../components/AuthButton";
import AuthInput from "../components/AuthInput";
import { TbEye, TbEyeClosed } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import { MdAccountCircle } from "react-icons/md";

const Signup = () => {
  const currentUrl = window.location.origin + "/verify";

  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submitForm = (data) => {
    setLoading(true);
    data = { ...data, callbackUrl: currentUrl };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, data)
      .then((response) => {
        setLoading(false);
        toast({ title: "Registration successful", status: "success" });
        navigate("/signin");
      })
      .catch((error) => {
        setLoading(false);
        toast({
          title: error.response.data.message || "An Error Occured",
          status: "warning",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box
      width="full"
      height={"100vh"}
      padding={"20px"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <HStack
        width={"full"}
        // borderColor={"black"}
        // borderWidth={1}
        // borderStyle={"solid"}
        height={"95%"}
        gap={["0px", "0px", "51px"]}
      >
        <Box
          width={["0%", "50%", "50%"]}
          height={"100%"}
          borderRadius={"32px"}
          background="white"
          display={("none", "flex", "flex")}
          justifyContent={"center"}
          alignItems={"center"}
          bgImage={"url(/assets/images/bg.png)"}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
        >
          <Image src="/assets/images/crypto1.png" />
        </Box>
        <Box
          width={["full", "100%", "50%"]}
          flex={1}
          paddingRight={["0px", "0px", "190px"]}
          display={"flex"}
          flexDir={"column"}
          marginLeft={0}
          alignItems={["center", "center", "start"]}
          py={"30px"}
        >
          <form style={{ width: "100%" }} onSubmit={handleSubmit(submitForm)}>
            <VStack
              width={"full"}
              alignItems={"flex-start"}
              gap={"1"}
              maxHeight={"90%"}
            >
              <Text
                color={"#141414"}
                fontWeight={"700"}
                fontSize={["30px", "36px", "48px"]}
                textAlign={["center", "left", "left"]}
                lineHeight={"normal"}
                marginBottom={"28px"}
              >
                {" "}
                Create an
                <br /> Account
              </Text>

              <AuthInput
                type={"text"}
                name={"firstName"}
                register={register("firstName")}
                placeholder={"First Name"}
                icon={<MdAccountCircle />}
                required={true}
              />
              <AuthInput
                type={"text"}
                name={"lastName"}
                register={register("lastName")}
                placeholder={"Last Name"}
                icon={<MdAccountCircle />}
                required={true}
              />
              <AuthInput
                type={"text"}
                name={"email"}
                register={register("email")}
                placeholder={"Email Address"}
                icon={<EmailIcon />}
                required={true}
              />
              <InputGroup
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <AuthInput
                  type={!togglePassword ? "password" : "text"}
                  name={"password"}
                  register={register("password")}
                  placeholder={"Password"}
                  icon={<LockIcon />}
                  width={"100%"}
                  height={"100%"}
                  border="none"
                  outline={"none"}
                />
                <InputRightElement mt={"2px"}>
                  <Text onClick={() => setTogglePassword(!togglePassword)}>
                    {togglePassword ? <TbEyeClosed /> : <TbEye />}
                  </Text>{" "}
                </InputRightElement>
              </InputGroup>

              <Text
                fontSize={["12px", "13px", "14px"]}
                color={"gray"}
                listStyleType={"inherit"}
                marginTop={"-12px"}
              >
                Minimum of 8 characters, must contain at least an Upper-Case
                character, at least a number and special character
              </Text>
              <InputGroup
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <AuthInput
                  type={!togglePassword ? "password" : "text"}
                  name={"confirmPassword"}
                  register={register("confirmPassword")}
                  placeholder={"Confirm Password"}
                  icon={<LockIcon />}
                />
                <InputRightElement mt={"2px"}>
                  <Text onClick={() => setTogglePassword(!togglePassword)}>
                    {togglePassword ? <TbEyeClosed /> : <TbEye />}
                  </Text>{" "}
                </InputRightElement>
              </InputGroup>
              <HStack
                marginBottom={"50px"}
                alignItems={"center"}
                alignSelf={"flex-start"}
                gap={"10px"}
                color={"gray"}
                fontSize={["14px", "14px", "16px"]}
                required={true}
              >
                <Checkbox required />
                <Text>I agree with Terms and Policy</Text>
              </HStack>

              <Box
                width="full"
                display={"flex"}
                flexDir={"column"}
                alignItems={"center"}
                gap={"24px"}
                marginBottom={"12px"}
              >
                <AuthButton
                  bg="black"
                  color="white"
                  type={"submit"}
                  isLoading={loading}
                >
                  Sign Up
                </AuthButton>
                <AuthButton bg="#F6FFF5" color="black">
                  <Image src="/assets/images/google.png" />
                  Sign up with Google{" "}
                </AuthButton>
              </Box>
              <HStack>
                <Text display={"flex"} gap={"2px"} alignSelf={"center"}>
                  Already have an account?{" "}
                </Text>
                <Link to={"/signin"}>
                  <Text fontWeight={700} cursor={"pointer"}>
                    Log In
                  </Text>
                </Link>
              </HStack>
            </VStack>
          </form>
        </Box>
      </HStack>
    </Box>
  );
};

export default Signup;
