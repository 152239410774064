import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import DashNav from "../components/DashNav";
import {
  DashCarouselOne,
  DashCarouselTwo,
  MobileCarousel1,
  MobileCarousel2,
} from "../components/DashCarousel";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Dashboard = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const heroData = [
    {
      id: 1,
      title: "Calculator",
      data: "Use proper risk management by calculating your risk ",
      link: "/calculator",
      src: "../../assets/images/calculator2.png",
    },
    {
      id: 2,
      title: "Journal",
      data: "Write, track, analyze, and achieve your goals effectively with our AI powered journaling tool. ",
      src: "../../assets/images/provide/one.png",
    },
    {
      id: 3,
      title: "Chat AI",
      data: " Your AI mentor",
      link: "/chat",
      src: "../../assets/images/robot.webp",
      robot: true,
    },
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/users/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("quidsToken")}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        const statusCode = error.response.data.statusCode;

        if (statusCode === 401) {
          toast({
            title: "Unauthorized access. Please log in to continue.",
            status: "warning",
          });
          navigate("/signin");
        } else {
          toast({ title: "An error occurred", status: "warning" });
        }
      });
  }, []);

  return (
    <VStack pb={"30px"}>
      <Box
        backgroundImage={"url('../../assets/images/bgdash.png')"}
        bgSize={"cover"}
        backgroundPosition={"20% 40%"}
        backgroundRepeat={"no-repeat"}
        width={"full"}
        height={"auto"}
      >
        <DashNav showlogo={true} />
        <Box
          display={"flex"}
          flexDir={["column", "row"]}
          justifyContent={"space-around"}
          paddingLeft={["none", "50px"]}
          px={["10px", "20px"]}
          py={["40px", "60px"]}
        >
          <VStack
            justifyContent={"flex-start"}
            alignItems={["center", "flex-start"]}
            gap={2}
          >
            <Text
              fontSize={["26px", "44px"]}
              fontWeight={"600"}
              color={"black"}
              pb={"30px"}
              textAlign={["center", "left"]}
              textTransform={"capitalize"}
            >
              <br />
              Revolutionizing the Forex and Web 3 spaces!
            </Text>

            {heroData.map((item) => (
              <HStack
                fontSize={["14px", "", "16px"]}
                fontWeight={"500"}
                cursor={"pointer"}
                bgColor={"#C7EBC9"}
                p={"5px"}
                w={["100%", "80%"]}
                onClick={() => navigate(item.link)}
              >
                <Box
                  minWidth={["90px"]}
                  height={["90px"]}
                  border={"3px solid white"}
                  borderRadius={"8px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  bgColor={"#1B4B1D;"}
                >
                  <Image
                    src={item.src}
                    width={item.robot && "100%"}
                    height={item.robot && "100%"}
                  />
                </Box>
                <VStack
                  color={"black"}
                  bgColor={"transparent"}
                  p={"20px 40px"}
                  borderRadius={"8px"}
                  alignItems={"flex-start"}
                >
                  <Text fontSize={"15px"} fontWeight={"600"}>
                    {item.title}
                  </Text>
                  <Text fontSize={"12px"} fontWeight={"500"}>
                    {item.data}
                  </Text>
                </VStack>
              </HStack>
            ))}
          </VStack>
          <Image src="../../assets/images/dashboardHero.png" />
        </Box>
      </Box>
      <Box
        px={"20px"}
        py={"40px"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        gap={"30px"}
      >
        <Text
          color={"#1B4B1D"}
          fontSize={["", "28px"]}
          fontWeight={"600"}
          textAlign={"center"}
        >
          Quidx Ai Products
        </Text>
        <HStack
          justifyContent={["left", "center"]}
          alignItems={["center"]}
          gap={"30px"}
          overflow={["scroll"]}
          width={"full"}
          display={["none", "flex"]}
          maxWidth={["90%", "full"]}
        >
          <Text
            fontSize={"16px"}
            color={"black"}
            p={"10px 30px"}
            _hover={{
              bg: "traansparent",
              border: "2px solid #3EA842",
              borderRadius: "4px",
            }}
          >
            All
          </Text>
          <Text
            fontSize={["14px", "16px"]}
            color={"black"}
            p={"10px 30px"}
            _hover={{
              bg: "transparent",
              border: "2px solid #3EA842",
              borderRadius: "4px",
            }}
          >
            Calculators
          </Text>
          <Text
            fontSize={["14px", "16px"]}
            color={"black"}
            p={"10px 30px"}
            _hover={{
              bg: "transparent",
              border: "2px solid #3EA842",
              borderRadius: "4px",
            }}
          >
            Educational Resources
          </Text>
          <Text
            fontSize={["14px", "16px"]}
            color={"black"}
            p={"10px 30px"}
            _hover={{
              bg: "transparent",
              border: "2px solid #3EA842",
              borderRadius: "4px",
            }}
          >
            Journals
          </Text>
        </HStack>
        <DashCarouselOne />
        <MobileCarousel1 />
      </Box>
      <Box
        px={"20px"}
        py={"40px"}
        display={"flex"}
        flexDir={"column"}
        justifyContent={"center"}
        bgColor={"#ECF8ED"}
        gap={"40px"}
      >
        <Text
          color={"#1B4B1D"}
          fontSize={["", "28px"]}
          fontWeight={"600"}
          textAlign={"center"}
        >
          QuidX Community{" "}
        </Text>
        <DashCarouselTwo />
        <MobileCarousel2 />
      </Box>
      <HStack
        display={"flex"}
        flexDir={["column", "row"]}
        width={"full"}
        gap={[5, 5, 10]}
        alignItems={"center"}
        bg={"#1B4B1D"}
        boxShadow={"0px 1px 4px rgba(255, 255, 255, 0.04)"}
        borderRadius={"24px"}
        p={["0px", "96px 56px"]}
        justifyContent={["center", "space-between"]}
        border={"10px solid #F2F2F2"}
        py={"70px"}
        paddingRight={["0px", "160px"]}
      >
        <Text
          color={"#F8FCF8"}
          fontWeight={"500"}
          fontSize={["14px", "14px", "30px"]}
          textAlign={["center", "left"]}
        >
          Ready to Explore Quidx? Claim <br />
          your free account{" "}
        </Text>

        <Button
          bg={"#DAF1DB"}
          color={"black"}
          height={"60px"}
          borderRadius={"4px"}
          fontWeight={"500"}
          paddingX={"70px"}
        >
          Get Started
        </Button>
      </HStack>{" "}
    </VStack>
  );
};

export default Dashboard;
