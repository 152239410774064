import { Box, Button, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import "../App.css";

const ProductsDiv = () => {
  const provide = [
    {
      id: 1,
      title: "Risk Calculator",
      body: " Use proper risk management by calculating your risk with just a few clicks Use proper risk management by calculating your risk with just a few clicks",
    },
    {
      id: 2,
      title: "Journal Tool",
      body: "Our platform provides comprehensive charts, indicators, and market analysis, empowering you to make informed trading decisions based on accurate information.",
    },
  ];
  const [start, setStart] = useState(0);

  // const variants = {
  //   initial: { opacity: 0, x: -10 },
  //   animate: { opacity: 1, x: 0 },
  //   exit: { opacity: 0, x: 10 },
  // };
  const handleNext = () => {
    setStart(start === provide.length - 1 ? 0 : start + 1);
  };

  const handlePrev = () => {
    setStart(start === 0 ? provide.length - 1 : start - 1);
  };

  return (
    <VStack display={["block", "none"]} width={"100%"}>
      <HStack width={"full"} gap={["25px", "25px", "25px"]}>
        <AnimatePresence initial={false} custom={start}>
          {provide.map(
            (item, i) =>
              i === start && (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Box
                    color={"#fff"}
                    display={"flex"}
                    flexDirection={"column"}
                    bg={"#3EA842"}
                    alignItems={"center"}
                    borderRadius={"36px"}
                    transition={"all 0.3s ease"}
                    py={["30px", "50px"]}
                    justifyContent={"space-around"}
                    gap={["20px", "30px"]}
                    maxWidth={["100%", "260px"]}
                    maxHeight={["100%", "450px"]}
                    className="slide-in-bottom"
                  >
                    <Box
                      width={["80px"]}
                      height={["80px"]}
                      border={"3px solid white"}
                      borderRadius={"8px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      bgColor={"#F8FCF8"}
                      transition={"all 0.6s ease"}
                    >
                      <Image src="../../assets/images/calculator.png" />
                    </Box>
                    <Text fontSize={["16px", "20px"]} fontWeight={["600"]}>
                      {item.title}
                    </Text>
                    <Text
                      fontSize={["13px", "14px"]}
                      fontWeight={["500"]}
                      w={["70%", "80%"]}
                    >
                      {item.body}
                    </Text>
                    <Button
                      backgroundColor={"#fff"}
                      color={"#3EA842"}
                      variant="ghost"
                      borderRadius={"6px"}
                      fontSize={["12px", "14px", "16px"]}
                      _hover={{ bg: "#083205", color: "#fff" }}
                      width={"fit-content"}
                    >
                      Learn More{" "}
                    </Button>
                  </Box>
                </motion.div>
              )
          )}
        </AnimatePresence>
      </HStack>
      <br />
      <HStack width={"full"} justifyContent={"space-between"}>
        <Box
          p={"12px"}
          bg={"#DAF1DB"}
          borderRadius={"8px"}
          _hover={{ p: "13px", bg: "#3EA842", color: "#fff" }}
          onClick={handlePrev}
        >
          <MdArrowBack size={"20px"} />
        </Box>
        <Box
          p={"12px"}
          bg={"#3EA842"}
          color={"#fff"}
          borderRadius={"8px"}
          _hover={{ p: "13px" }}
          onClick={handleNext}
        >
          <MdArrowForward size={"20px"} />
        </Box>
      </HStack>
    </VStack>
  );
};
export default ProductsDiv;
