import React from "react";
import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import Footer from "../components/Footer";
import Checkout from "../components/Checkout";

const Subscriptions = () => {
  return (
    <VStack
      paddingTop={"30px"}
      h={"fit-content"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <HStack
        h={"fit-content"}
        w={"full"}
        paddingX={["0px", "40px"]}
        display={"flex"}
        justifyContent={["center", "space-around", "space-around"]}
        alignItems={"center"}
      >
        <Image
          src="/assets/images/sub2.png"
          height={"full"}
          w={"573px"}
          h={"744px"}
          borderRadius={"15px"}
          display={["none", "block", "block"]}
        />
        <VStack paddingY="30px" display="flex">
          <Text
            textAlign={"center"}
            fontSize={["24px", "28px", "32px"]}
            fontWeight={600} 
          >
            Subscriptions
          </Text>
          <Text
            textAlign={"center"}
            fontSize={["18px", "20px", "24px"]}
            fontWeight={500}
          >
            Choose the Package That Fits Your Trading Goals
          </Text>
          <Box height={"20px"} display={["none", "block"]}></Box>
          <Checkout type={"Monthly"} price={49} monthly={true} />
          <Checkout type={"Yearly"} price={49} monthly={false} />
          <Box height={"20px"}></Box>
          <Text
            textAlign={"center"}
            fontSize={["18px", "20px", "24px"]}
            fontWeight={500}
            textDecor={"underline"}
          >
            7-days free trial{" "}
          </Text>
          <Text
            textAlign={"center"}
            fontSize={["18px", "20px", "24px"]}
            fontWeight={500}
            textDecor={"underline"}
          >
            Manage subscription {" "}
          </Text>{" "}
        </VStack>
      </HStack>
      <Box height={"30px"}></Box>

      <Footer />
    </VStack>
  );
};

export default Subscriptions;
