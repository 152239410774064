import { VStack, Box } from "@chakra-ui/react";
import React from "react";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import Footer from "./Footer";

const ThemeLayout = (props) => {
  return (
    <VStack width={"full"} backgroundColor={"#F8FCF8"}>
      <Box width={"full"}>{props.children}</Box>
      <Footer />
    </VStack>
  );
};

export default ThemeLayout;
